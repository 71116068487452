import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"

const CustomLink = styled(Link)`
  font-size: 1.2rem;
  color: #000;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  opacity: 1;
  box-shadow: inset 0 -1px 0 #000001;
  padding-bottom: 0.1rem;
  &:hover {
    box-shadow: inset 0 -2px 0 #000001;
  }
`

export default function ArrowLink({ children, isBackArrow, to }) {
  const nextArrow = <>&rarr;</>
  const pastArrow = <>&larr;</>
  return (
    <CustomLink to={to}>
      {isBackArrow ? pastArrow : nextArrow}&nbsp;{children}
    </CustomLink>
  )
}
